.leads {
    .actions {
        float: right;
    }

    .ant-table {
        .ant-table-row.closed {
            opacity: 0.5;
        }

        .follow-up {
            .ant-badge-count {
                background-color: #878682;
            }
        }
    }

    .columns {
        width: 100%;

        > .ant-space-item {
            width: calc(~'100% / 6');

            /* Column Card */
            > .ant-card {
                > .ant-card-head {
                    padding: 12px 12px 4px 12px;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: normal;
                    background-color: #f5f5f5;
                    min-height: auto;

                    .ant-card-head-title {
                        padding: 0;

                        .amount {
                            font-size: 12px;
                            color: rgba(0, 0, 0, 0.5);
                        }
                    }
                }

                > .ant-card-body {
                    padding: 8px;
                    background-color: #f5f5f5;

                    > .ant-space {
                        width: 100%;

                        /* Lead Card */
                        .ant-card {
                            &.follow-up-time-today {
                                border: 2px solid #faad14;
                            }

                            &.follow-up-time-expired {
                                border: 2px solid #ff4d4f;
                            }

                            .ant-card-body {
                                padding: 16px;

                                .time-in-status {
                                    float: right;

                                    &.default .ant-badge-count {
                                        background-color: #d9d9d9;
                                    }

                                    &.warning .ant-badge-count {
                                        background-color: #faad14;
                                    }

                                    &.danger .ant-badge-count {
                                        background-color: #ff4d4f;
                                    }
                                }

                                .code {
                                    font-size: 11px;
                                    color: #999;
                                }

                                .name {
                                    font-weight: bold;
                                }

                                .lvr {
                                    width: 50%;
                                    display: inline-block;
                                    font-size: 12px;
                                }

                                .property-type {
                                    width: 50%;
                                    display: inline-block;
                                    text-align: right;
                                }

                                .follow-up-time {
                                    font-size: 11px;
                                    color: #999;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.leads-dashboard-change-filters-modal {
    .ant-form-item-label {
        width: 90px;
    }
}

.lead-add-modal {
    .ant-form-item-label {
        width: 200px;
    }

    .mortgage-type .ant-select {
        width: 250px;
    }

    .loan-purpose .ant-select {
        width: 250px;
    }

    .term-months .ant-select {
        width: 200px;
    }

    .repayment-strategy .ant-select {
        width: 250px;
    }

    .loan-amount .ant-input-group {
        width: 200px;
    }

    .broker .ant-select {
        width: 250px;
    }

    .bdm .ant-select {
        width: 250px;
    }

    .referral-partner-phone,
    .referral-partner-first-name,
    .referral-partner-last-name,
    .referral-partner-company-name,
    .phone,
    .first-name,
    .last-name {
        .ant-input {
            width: 200px;
        }
    }

    .referral-partner-email,
    .email {
        .ant-input {
            width: 250px;
        }
    }
}

.lead-close-modal {
    .ant-form-item-label {
        width: 200px;
    }
}

.lead-search-option {
    .option-name {
        margin-left: 5px;
    }

    .option-tag {
        margin: 0px 5px;
    }
}
