.leads-scenario-calculator {
    .ant-form-item-label {
        width: 220px;
    }

    .mortgage-type .ant-select {
        width: 200px;
    }

    .term-months .ant-select {
        width: 200px;
    }

    .loan-amount .ant-input-group {
        width: 200px;
    }

    .current-debt .ant-input-group {
        width: 200px;
    }

    .property-value .ant-input-group {
        width: 200px;
    }

    .lvr .ant-input-group {
        width: 150px;
    }

    .postcode .ant-input {
        width: 100px;
    }

    .zone-type .ant-select {
        width: 250px;
    }

    .term-months .ant-input {
        width: 100px;
    }

    .interest-rate .ant-input-group {
        width: 150px;
    }

    .default-interest-rate .ant-input-group {
        width: 150px;
    }

    .maximum-lvr .ant-input-group {
        width: 150px;
    }

    .commitment-fee .ant-input-group {
        width: 200px;
    }

    .establishment-fee-dollars .ant-input-group {
        width: 200px;
    }

    .establishment-fee-percentage .ant-input-group {
        width: 150px;
    }

    .application-fee-dollars .ant-input-group {
        width: 200px;
    }

    .application-fee-percentage .ant-input-group {
        width: 150px;
    }

    .brokerage-fee-dollars .ant-input-group {
        width: 200px;
    }

    .brokerage-fee-percentage .ant-input-group {
        width: 150px;
    }

    .legal-fees .ant-input-group {
        width: 200px;
    }

    .approximate-outlays .ant-input-group {
        width: 200px;
    }

    .loan-amount-total .ant-input-group {
        width: 200px;
    }

    .interest-payable .ant-input-group {
        width: 200px;
    }

    .establishment-fee-total .ant-input-group {
        width: 200px;
    }

    .application-fee-total .ant-input-group {
        width: 200px;
    }

    .brokerage-fee-total .ant-input-group {
        width: 200px;
    }

    .legal-fees-total .ant-input-group {
        width: 200px;
    }

    .total-costs-dollars .ant-input-group {
        width: 200px;
    }

    .total-costs-percentage .ant-input-group {
        width: 150px;
    }

    .total-costs-percentage-per-annum .ant-input-group {
        width: 150px;
    }

    .monthly-interest-payments .ant-input-group {
        width: 200px;
    }

    .monthly-interest-payments-default .ant-input-group {
        width: 200px;
    }

    .gross-balance-on-settlement .ant-input-group {
        width: 200px;
    }

    .net-prepaid-balance-on-settlement .ant-input-group {
        width: 200px;
    }

    .net-monthly-balance-on-settlement .ant-input-group {
        width: 200px;
    }
}
