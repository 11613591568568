.ant-layout.investment {
    > .header {
        .interest-rate {
            .ant-btn-link {
                padding: 0;
                height: auto;
            }
        }

        .workflow-status {
            margin-right: 0px;
        }
    }

    .content {
        &.overview {
            .photo {
                overflow: hidden;
                width: 604px;
                height: 360px;
                border-radius: 8px;

                img {
                    margin-top: -87px;
                    width: 100%;
                }
            }

            .ant-descriptions {
                margin-top: 20px;
            }
        }

        &.edit {
            .ant-form-item-label {
                width: 200px;
            }

            .photo {
                .ant-upload {
                    margin-top: 20px;
                    display: block;
                }
            }

            .description .ant-form-item-control-input {
                width: 800px;
            }

            .memo-synopsis .ant-input {
                width: 800px;
            }

            .property-security .ant-input {
                width: 400px;
            }

            .property-type .ant-input {
                width: 400px;
            }
        }

        &.history {
            .ant-timeline-item {
                .ant-timeline-item-label {
                    width: calc(~'20% - 12px');
                }

                .ant-timeline-item-tail {
                    left: 20%;
                }

                .ant-timeline-item-head {
                    left: 20%;
                }

                .ant-timeline-item-content {
                    left: calc(~'20% - 4px');
                    width: calc(~'80% - 14px');
                    white-space: pre-wrap;
                }
            }
        }
    }
}

.investment-show-modal {
    .ant-form-item-label {
        width: 200px;
    }
}