.brokers {
    .map-container {
        .brokers-map {
            height: 600px;
            width: 100%;

            .gm-style .gm-style-iw-c {
                padding: 0;

                button {
                    display: none !important;
                }
            }

            .gm-style .gm-style-iw-d {
                overflow: auto !important;

                .marker-card {
                    padding: 0px;

                    .ant-card-body {
                        padding: 12px;
                    }
                }
            }
        }
    }
}
