.warehouses {
    .secondary-proposed-actions {
        margin-left: -32px;
    }
    &.proposed-sales {
        table {
            tr {
                td {
                    &.date,
                    &.source-warehouse,
                    &.loan,
                    &.current-holding,
                    &.sale-total,
                    &.proposed-actions {
                        vertical-align: top;
                    }
                }
            }
        }
    }

    .portfolio-dashboard {
        .ant-table-content {
            .metric-name {
                background: #fafafa;
                font-weight: 500;
            }
        }
    }
}
