.ant-layout.investors {
    main.content {
        & > .actions {
            float: right;
        }

        & > .columns {
            width: 100%;

            > .ant-space-item {
                width: calc(~'100% / 7');

                > .ant-card {
                    > .ant-card-head {
                        padding: 12px 12px 4px 12px;
                        text-transform: uppercase;
                        font-size: 14px;
                        font-weight: normal;
                        background-color: #f5f5f5;
                        min-height: auto;

                        .ant-card-head-title {
                            padding: 0;
                        }
                    }

                    > .ant-card-body {
                        padding: 8px;
                        background-color: #f5f5f5;

                        > .ant-space {
                            width: 100%;

                            .ant-card-body {
                                display: flex;
                                flex-direction: column;
                                row-gap: 5px;

                                .name {
                                    font-weight: bold;
                                    width: 100%;
                                    margin-top: -5px;
                                    padding-right: 14px;
                                    overflow-wrap: break-word;
                                }

                                .approval-status {
                                    position: absolute;
                                    align-self: flex-end;
                                }

                                .reschedule {
                                    font-size: 11px;
                                }

                                .im-selector {
                                    display: block;

                                    svg {
                                        fill: #999;
                                        margin-right: 5px;
                                    }
                                }

                                .account-types {
                                    width: 100%;
                                    margin-bottom: -5px;
                                    row-gap: 8px;
                                    display: flex;
                                    flex-wrap: wrap;

                                    .ant-tag {
                                        margin: 0;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }

                    .contact-today {
                        border: 2px solid #00cc00;
                    }

                    .contact-overdue {
                        border: 3px solid #ff4d4f;
                    }
                }
            }
        }

        .ant-table {
            tr {
                td {
                    .balance-total {
                        .ant-space-item {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}

.investors-search-dropdown {
    .investor-search-option {
        .name {
            display: block;
        }
    }
}

.investor-next-contact-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .date .ant-input {
        width: 200px;
    }
}

.investors-dashboard-change-filters-modal {
    .ant-form-item-label {
        width: 120px;
    }
}

.investor-adviser-change-modal {
    .adviser .ant-select {
        width: 330px;
    }

    .ant-form-item-label {
        width: 60px;
    }
}
