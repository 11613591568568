.applications {
    .actions {
        float: right;
    }

    .columns {
        width: 100%;

        > .ant-space-item {
            width: calc(~'100% / 7');

            /* Column Card */
            > .ant-card {
                > .ant-card-head {
                    padding: 12px 12px 4px 12px;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: normal;
                    background-color: #f5f5f5;
                    min-height: auto;

                    .ant-card-head-title {
                        padding: 0;

                        .amount {
                            font-size: 12px;
                            color: rgba(0, 0, 0, 0.5);
                        }
                    }
                }

                > .ant-card-body {
                    padding: 8px;
                    background-color: #f5f5f5;

                    > .ant-space {
                        width: 100%;

                        /* Application Card */
                        .ant-card {
                            &.follow-up-time-today {
                                border: 2px solid #faad14;
                            }

                            &.follow-up-time-expired {
                                border: 2px solid #ff4d4f;
                            }

                            &.settles-soon {
                                border: 2px solid #faad14;
                            }

                            &.settlement-expired {
                                border: 2px solid #ff4d4f;
                            }

                            .ant-card-body {
                                padding: 16px;

                                .assignee-officer,
                                .assignee-processor {
                                    display: block;

                                    svg {
                                        fill: #999;
                                        margin-right: 5px;
                                    }
                                }

                                .code {
                                    font-size: 11px;
                                    color: #999;
                                }

                                .time-in-status {
                                    float: right;

                                    &.default .ant-badge-count {
                                        background-color: #d9d9d9;
                                    }

                                    &.warning .ant-badge-count {
                                        background-color: #faad14;
                                    }

                                    &.danger .ant-badge-count {
                                        background-color: #ff4d4f;
                                    }
                                }

                                .name {
                                    font-weight: bold;
                                }

                                .interest-rate {
                                    font-size: 12px;
                                }

                                .follow-up-time,
                                .settlement-date {
                                    font-size: 11px;
                                    color: #999;
                                }
                            }
                        }
                    }
                }
            }

            &:nth-child(5) {
                > .ant-card {
                    > .ant-card-head,
                    > .ant-card-body {
                        background-color: #fec;
                    }
                }
            }
        }
    }

    &.forecast {
        .expired-settlements {
            margin-bottom: 30px;

            .ant-table-content {
                background: #FBE4E8;

                tr:hover {
                    background: #FBE4E8;

                    td {
                        background: #FBE4E8;
                    }
                }
            }
        }

        .forecast-current {
            table {
                tr {
                    td {
                        &.date,
                        &.day,
                        &.total {
                            vertical-align: top;
                        }
                    }
                }
            }
        }
    }

    .dashboard-filter {
        min-width: 200px;
    }
}

.applications-dashboard-change-filters-modal {
    .ant-form-item-label {
        width: 120px;
    }
}

.application-search-option {
    .option-name {
        margin-left: 5px;
    }

    .option-tag {
        margin: 0px 5px;
    }
}

.application-properties {
    .add-property {
        float: right;
    }
}

.settlement-forecast-change-filters-modal {
    .ant-form-item-label {
        width: 120px;
    }
}
