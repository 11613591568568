.warehouse {
    main {
        &.proposed-purchases {
            h3 {
                margin-top: 30px;
            }

            .secondary-proposed-actions {
                margin-left: -32px;
            }
        }

        &.proposed-sales {
            .secondary-proposed-actions {
                margin-left: -32px;
            }
            table {
                tr {
                    td {
                        &.date,
                        &.source-warehouse,
                        &.loan,
                        &.current-holding,
                        &.sale-total,
                        &.proposed-actions {
                            vertical-align: top;
                        }
                    }
                }
            }
        }

        &.warehouse-active-report {
            .active-warehouse-loans-count,
            .active-warehouse-loans-value {
                margin: 5px;
                margin-top: 20px;
                background-color: #fafafa;
                padding: 8px;

                &.active-warehouse-loans-count {
                    margin-left: 0px;
                }

                &.active-warehouse-loans-value {
                    margin-right: 0px;
                }

                .ant-statistic-content-value {
                    color: #75d7ad;
                    font-size: 1.2em;
                    margin-bottom: 16px;
                }
            }
            .pie-chart-left,
            .pie-chart-right {
                background-color: #fafafa;
                margin: 5px;
                padding: 8px;
                &.pie-chart-left {
                    margin-left: 0px;
                }
                &.pie-chart-right {
                    margin-right: 0px;
                }
            }
        }
    }
}

.warehouse-adjust-interest-rate-modal {
    .ant-form-item-label {
        width: 140px;
    }

    .interest-rate .ant-input-group {
        width: 150px;
    }
}

.warehouse-adjust-principal-modal {
    .ant-form-item-label {
        width: 140px;
    }

    .a-notes .ant-input {
        width: 200px;
    }

    .amount .ant-input {
        width: 200px;
    }

    .capacity-limit .ant-input {
        width: 200px;
    }

    .principal-draw .ant-input {
        width: 200px;
    }

    .seller-notes .ant-input {
        width: 200px;
    }

    .x-notes .ant-input {
        width: 200px;
    }
}

.warehouse-propose-sale-modal {
    h3 {
        text-align: center;
        margin-bottom: 24px;
    }

    .ant-form-item-label {
        width: 210px;
    }

    .amount-principal {
        .ant-input-group {
            width: 228px;
        }
    }

    .transaction-date {
        .transaction-pick-date {
            width: 130px;
        }

        .transaction-pick-time {
            width: 90px;
        }
    }

    .ant-form-item-explain-error {
        inline-size: 228px;
        overflow-wrap: break-word;
    }
}

.warehouse-propose-purchase-modal {
    h3 {
        text-align: center;
        margin-bottom: 24px;
    }

    .ant-form-item-label {
        width: 210px;
    }

    .transaction-date {
        .transaction-pick-date {
            width: 130px;
        }

        .transaction-pick-time {
            width: 90px;
        }
    }

    .loading-spinner {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}