.ant-layout.investor {
    .content {
        &.account {
            .actions {
                float: right;

                .other-actions {
                    margin-left: -32px;
                }
            }
        }

        &.bank-accounts {
            .actions {
                float: right;
            }
        }

        &.overview {
            .ant-descriptions-title {
                margin-top: 30px;
            }
        }

        &.transactions {
            .ant-table-cell {
                vertical-align: top;

                &.description {
                    white-space: pre-wrap;
                }
            }
        }

        &.monthly-statements {
            .actions {
                float: right;
            }
        }

        &.annual-statements {
            .actions {
                float: right;
            }

            .add-statement {
                float: right;

                .ant-upload-list {
                    display: none;
                }
            }
        }

        &.edit {
            .ant-form-item-label {
                width: 200px;
            }

            .name .ant-input {
                width: 400px;
            }

            .available-funds-type .ant-select {
                width: 300px;
            }

            .investor-manager .ant-select {
                width: 400px;
            }

            .investor-type .ant-select {
                width: 300px;
            }

            .trust-name .ant-input {
                width: 400px;
            }

            .trust-tax-file-number .ant-input {
                width: 200px;
            }

            .trust-australian-business-number .ant-input {
                width: 200px;
            }

            .company-name .ant-input {
                width: 400px;
            }

            .company-tax-file-number .ant-input {
                width: 200px;
            }

            .company-australian-company-number .ant-input {
                width: 200px;
            }

            .company-street-address .ant-input {
                width: 400px;
            }

            .company-suburb .ant-input {
                width: 200px;
            }

            .company-postcode .ant-input {
                width: 100px;
            }

            .company-state .ant-input {
                width: 100px;
            }
        }

        &.documents {
            .add-document {
                float: right;

                .ant-upload-list {
                    display: none;
                }
            }
        }

        &.notes {
            .ant-form-item-label {
                width: 200px;
            }

            .ant-timeline-item {
                .ant-timeline-item-label {
                    width: calc(~'20% - 12px');
                }

                .ant-timeline-item-tail {
                    left: 20%;
                }

                .ant-timeline-item-head {
                    left: 20%;
                }

                .ant-timeline-item-content {
                    left: calc(~'20% - 4px');
                    width: calc(~'80% - 14px');
                    white-space: pre-wrap;
                }
            }

            .add-note {
                margin-left: 200px;
            }
        }

        &.referrals {
            .add {
                float: right;
            }
        }

        &.history {
            .ant-timeline-item {
                .ant-timeline-item-label {
                    width: calc(~'20% - 12px');
                }

                .ant-timeline-item-tail {
                    left: 20%;
                }

                .ant-timeline-item-head {
                    left: 20%;
                }

                .ant-timeline-item-content {
                    left: calc(~'20% - 4px');
                    width: calc(~'80% - 14px');
                    white-space: pre-wrap;
                }
            }
        }
    }
}

.investor-bank-account-add-modal {
    .ant-form-item-label {
        width: 150px;
    }
}

.investor-individual-add-modal,
.investor-individual-edit-modal {
    .ant-form-item-label {
        width: 200px;
    }

    .first-name .ant-input {
        width: 200px;
    }

    .last-name .ant-input {
        width: 200px;
    }

    .email .ant-input {
        width: 250px;
    }

    .date-of-birth .ant-input {
        width: 200px;
    }

    .tax-file-number .ant-input {
        width: 200px;
    }

    .street-address .ant-input {
        width: 250px;
    }

    .suburb .ant-input {
        width: 200px;
    }

    .postcode .ant-input {
        width: 100px;
    }

    .state .ant-input {
        width: 100px;
    }

    .country .ant-select {
        width: 250px;
    }

    .resident-type .ant-select {
        width: 200px;
    }

    .id-proof-type .ant-select {
        width: 250px;
    }

    .id-proof-number .ant-input {
        width: 200px;
    }

    .id-proof-expiry-date .ant-input {
        width: 200px;
    }
}

.investor-top-up-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .amount .ant-input {
        width: 200px;
    }
}

.investor-income-trust-divest-modal,
.investor-income-trust-invest-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .class .ant-select {
        width: 100px;
    }

    .amount .ant-input {
        width: 150px;
    }
    .ant-form-item-explain-error {
        inline-size: 228px;
        overflow-wrap: break-word;
    }
}

.investor-invest-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .investment .ant-select {
        width: 300px;
    }

    .amount .ant-input {
        width: 200px;
    }
}

.investor-reserve-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .investment .ant-select {
        width: 300px;
    }

    .amount .ant-input {
        width: 200px;
    }
}

.investor-annual-statement-add-modal {
    .statement-file .ant-form-item-label {
        width: 200px;
    }

    .statement-file {
        .ant-upload-list {
            width: 250px;
        }
    }

    .year {
        .ant-form-item-label {
            width: 200px;
        }

        .ant-form-item-explain {
            width: 250px;
        }

        .ant-select {
            width: 100px;
        }
    }
}

.investor-annual-statement-generate-modal {
    .year {
        .ant-form-item-label {
            width: 200px;
        }

        .ant-form-item-explain {
            width: 250px;
        }

        .ant-select {
            width: 100px;
        }
    }
}

.investor-monthly-statement-generate-modal {
    .date {
        .ant-form-item-label {
            width: 150px;
        }

        .ant-form-item-explain {
            width: 250px;
        }

        .ant-select {
            width: 150px;
        }
    }
}

.investor-monthly-statement-send-modal {
    .ant-form-item-label {
        width: 200px;
    }

    .first-name .ant-input {
        width: 200px;
    }

    .last-name .ant-input {
        width: 200px;
    }

    .email .ant-input {
        width: 250px;
    }
}
