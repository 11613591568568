.withdrawal-abas-list {
    .actions {
        float: right;
    }
}

.withdrawal-abas-add-modal {
    .ant-table-selection-column {
        width: 3%;
    }
}
