.administrators {
    .add {
        float: right;
    }
}

.administrator-add-modal {
    .ant-form-item-label {
        width: 150px;
    }
}
