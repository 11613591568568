.broker {
    main.edit {
        .ant-form-item-label {
            width: 200px;
        }

        .first-name .ant-input {
            width: 200px;
        }

        .last-name .ant-input {
            width: 200px;
        }

        .phone .ant-input {
            width: 200px;
        }

        .email .ant-input {
            width: 400px;
        }

        .company-name .ant-input {
            width: 300px;
        }

        .postal-address .ant-input {
            width: 400px;
        }

        .suburb .ant-input {
            width: 300px;
        }

        .state .ant-select {
            width: 100px;
        }

        .postcode .ant-input {
            width: 100px;
        }

        .type .ant-select {
            width: 300px;
        }

        .acl-number .ant-input {
            width: 200px;
        }

        .aggregator-name .ant-input {
            width: 200px;
        }

        .aggregator .ant-select {
            width: 300px;
        }

        .linkedin-url .ant-input {
            width: 400px;
        }

        .bdm .ant-select {   
            width: 300px;
        }
    }

    main.documents {
        table {
            .ant-upload-list {
                display: none;
            }
        }
    }

    main.notes {
        .ant-form-item-label {
            width: 200px;
        }

        .ant-timeline-item {
            .ant-timeline-item-label {
                width: calc(~'20% - 12px');
            }

            .ant-timeline-item-tail {
                left: 20%;
            }

            .ant-timeline-item-head {
                left: 20%;
            }

            .ant-timeline-item-content {
                left: calc(~'20% - 4px');
                width: calc(~'80% - 14px');
                white-space: pre-wrap;
            }
        }

        .add-note {
            margin-left: 200px;
        }
    }

    main.history {
        .ant-timeline-item {
            .ant-timeline-item-label {
                width: calc(~'20% - 12px');
            }

            .ant-timeline-item-tail {
                left: 20%;
            }

            .ant-timeline-item-head {
                left: 20%;
            }

            .ant-timeline-item-content {
                left: calc(~'20% - 4px');
                width: calc(~'80% - 14px');
                white-space: pre-wrap;
            }
        }
    }
}
