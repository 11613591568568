@teal: #59c5c7;
@grey: #f5f5f5;
@black: #1a1a1a;
@charcoal: #333;

@navy: #16254c;
@navy-light: rgb(62, 83, 125);
@navy-dark: rgb(9, 20, 50);

@blue: #5dc4ed;
@blue-light: rgb(185, 228, 247);
@blue-dark: rgb(27, 155, 210);

body {
    background-color: @grey;
    color: @black;
    font-family: 'Poppins', sans-serif;
}

.ant-layout {
    background-color: @grey;
}

.ant-page-heading-title,
.ant-page-header-heading-title {
    color: @black;
    font-weight: 500;
}

.layout {
    height: 100%;

    &> .header {
        background-color: @navy;

        .santa {
            width: 25px;
            height: 25px;
            position: absolute;
            left: 159px;
            top: 4px;
        }

        .logo {
            width: 160px;
            margin: 10px 100px 0 0;
            float: left;
        }

        .top-menu {
            float: left;
            background-color: @navy;
            width: 900px;

            .ant-menu-title-content a {
                display: block;
                height: 64px;
                padding: 0 20px;
            }

            .ant-menu-item,
            .ant-menu-submenu {
                padding: 0;
            }

            .ant-menu-submenu:hover,
            .ant-menu-submenu-open {
                color: #fff;
            }

            .ant-menu-item-selected {
                background-color: @navy-light;

                a {
                    color: #fff;
                }
            }

            .ant-menu-item:hover {
                background-color: @navy-light;
            }

            .ant-menu-submenu-title {
                a {
                    color: rgba(255, 255, 255, 0.65);
                }
            }

            .ant-menu-submenu-active {
                background-color: @navy-light;
            }
        }

        .login-menu {
            float: right;
            background-color: @navy;

            .ant-menu-item-selected {
                background-color: @navy-light;

                a {
                    color: #fff;
                }
            }

            .ant-menu-submenu-active {
                color: #fff;
            }

            .user-menu-item {
                .anticon {
                    color: @navy;
                    background-color: rgba(255, 255, 255, 0.65);
                    border-radius: 20px;
                    padding: 10px;
                }

                &.ant-menu-submenu-active {
                    .anticon {
                        background-color: #fff;
                    }
                }
            }
        }
    }

    .body {
        padding: 0 50px;

        .passkey-not-enabled {
            width: 700px;
            text-align: center;
            margin: 16px auto 0 auto;
        }

        .breadcrumb {
            margin: 16px 0;
        }

        .header {
            margin-bottom: 20px;
        }

        .content-wrapper {
            padding: 24px 0;
            background-color: #fff;

            .menu {
                ul {
                    height: 100%;
                    border-right: 0;

                    li {
                        color: @navy;
                    }

                    .ant-menu-item-selected {
                        background-color: @blue-light;

                        &::after {
                            border-right-color: @blue-dark;
                        }

                        &> a {
                            color: @navy;
                        }
                    }

                    .ant-menu-item-active {
                        &> a {
                            color: @navy;
                        }
                    }
                }
            }

            .content {
                padding: 0 24px;
                background-color: #fff;

                h2,
                h3 {
                    font-weight: 500;
                }

                .actions {
                    float: right;
                }

                .ant-btn {
                    &> .mff {
                        position: relative;
                        top: 2px;
                        margin-left: 6px;
                    }

                    &:hover > .mff {
                        left: 4px;
                    }
                }

                .ant-table-summary td {
                    background-color: #fafafa;
                    font-weight: 500;
                }
            }
        }
    }
}

.accounts-menu,
.applications-menu,
.brokers-menu,
.investments-menu,
.investors-menu,
.leads-menu,
.loans-menu,
.other-menu,
.reports-menu,
.users-menu,
.user-menu,
.warehouses-menu {
    .ant-menu-title-content {
        a {
            color: rgba(255, 255, 255, 0.65);
        }
    }

    .ant-menu-sub {
        background-color: @navy !important;

        li {
            &.ant-menu-submenu:hover,
            &.ant-menu-item-active,
            &.ant-menu-item-selected {
                background-color: @navy-light !important;
            }

            &.ant-menu-submenu-active {
                color: #fff;
            }
        }
    }
}

.search-dropdown {
    .input-wrapper {
        padding: 12px 12px 6px 12px;

        input {
            width: 188px;
            margin-bottom: 8px;
            display: block;
        }
    }
}
