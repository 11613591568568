.adviser {
    main.edit {
        .ant-form-item-label {
            width: 270px;
        }

        .first-name .ant-input {
            width: 200px;
        }

        .last-name .ant-input {
            width: 200px;
        }

        .phone .ant-input {
            width: 200px;
        }

        .email .ant-input {
            width: 400px;
        }

        .company-name .ant-input {
            width: 300px;
        }

        .dealer-group .ant-input {
            width: 300px;
        }

        .afsl-number .ant-input {
            width: 200px;
        }

        .authorised-representative-number .ant-input {
            width: 200px;
        }
    }

    main.notes {
        .ant-form-item-label {
            width: 200px;
        }

        .ant-timeline-item {
            .ant-timeline-item-label {
                width: calc(~'20% - 12px');
            }

            .ant-timeline-item-tail {
                left: 20%;
            }

            .ant-timeline-item-head {
                left: 20%;
            }

            .ant-timeline-item-content {
                left: calc(~'20% - 4px');
                width: calc(~'80% - 14px');
                white-space: pre-wrap;
            }
        }

        .add-note {
            margin-left: 200px;
        }
    }

    main.history {
        .ant-timeline-item {
            .ant-timeline-item-label {
                width: calc(~'20% - 12px');
            }

            .ant-timeline-item-tail {
                left: 20%;
            }

            .ant-timeline-item-head {
                left: 20%;
            }

            .ant-timeline-item-content {
                left: calc(~'20% - 4px');
                width: calc(~'80% - 14px');
                white-space: pre-wrap;
            }
        }
    }

    main.investors {
        table {
            tr {
                td {
                    .ant-space-item {
                        display: flex;
                    }
                }
            }
        }
    }
}
