.body > .login {
    width: 400px;
    margin: 0 auto;
    margin-top: 54px;

    .account-recovery {
        float: right;
        margin-top: 6px;
    }
}

.body > .account-recovery {
    width: 400px;
    margin: 0 auto;
    margin-top: 54px;

    .login {
        float: right;
        margin-top: 6px;
    }
}

.body > .login-link {
    width: 400px;
    margin: 0 auto;
    margin-top: 54px;
}