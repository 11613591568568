.referral-partner {
    .content {
        &.documents {
            .add-document {
                float: right;

                .ant-upload-list {
                    display: none;
                }
            }
        }

        &.edit {
            .ant-form-item-label {
                width: 200px;
            }

            .first-name .ant-input {
                width: 200px;
            }

            .last-name .ant-input {
                width: 200px;
            }

            .phone .ant-input {
                width: 200px;
            }

            .email .ant-input {
                width: 400px;
            }

            .company-name .ant-input {
                width: 300px;
            }

            .group-name .ant-input {
                width: 300px;
            }

            .street-address .ant-input {
                width: 400px;
            }

            .suburb .ant-input {
                width: 300px;
            }

            .state .ant-select {
                width: 100px;
            }

            .postcode .ant-input {
                width: 100px;
            }

            .type .ant-select {
                width: 200px;
            }

            .abn .ant-input {
                width: 200px;
            }
            .acl-number .ant-input {
                width: 200px;
            }

            .aggregator-name .ant-input {
                width: 200px;
            }

            .aggregator .ant-select {
                width: 300px;
            }

            .linked-in-url .ant-input {
                width: 400px;
            }

            .questions {
                .ant-form-item-label {
                    width: 300px;
                }
            }
        }

        &.history {
            .ant-timeline-item {
                .ant-timeline-item-label {
                    width: calc(~'20% - 12px');
                }

                .ant-timeline-item-tail {
                    left: 20%;
                }

                .ant-timeline-item-head {
                    left: 20%;
                }

                .ant-timeline-item-content {
                    left: calc(~'20% - 4px');
                    width: calc(~'80% - 14px');
                    white-space: pre-wrap;
                }
            }
        }

        &.overview {
            .ant-descriptions-title {
                margin-top: 30px;
            }
        }

        &.notes {
            .ant-form-item-label {
                width: 200px;
            }

            .ant-timeline-item {
                .ant-timeline-item-label {
                    width: calc(~'20% - 12px');
                }

                .ant-timeline-item-tail {
                    left: 20%;
                }

                .ant-timeline-item-head {
                    left: 20%;
                }

                .ant-timeline-item-content {
                    left: calc(~'20% - 4px');
                    width: calc(~'80% - 14px');
                    white-space: pre-wrap;
                }
            }

            .add-note {
                margin-left: 200px;
            }
        }
    }
}
