.lead {
    .overview {
        .comments {
            white-space: pre-wrap;
        }

        .landing-page {
            width: 66%;
        }
    }

    main.edit {
        .ant-form-item-label {
            width: 250px;
        }

        .mortgage-type .ant-select {
            width: 300px;
        }

        .loan-purpose .ant-select {
            width: 400px;
        }

        .loan-term .ant-select {
            width: 300px;
        }

        .term-months .ant-select {
            width: 200px;
        }

        .repayment-strategy .ant-select {
            width: 400px;
        }

        .property-value .ant-input-group {
            width: 200px;
        }

        .loan-amount .ant-input-group {
            width: 200px;
        }

        .current-debt .ant-input-group {
            width: 200px;
        }

        .property-type .ant-select {
            width: 200px;
        }

        .address .ant-input {
            width: 400px;
        }

        .suburb .ant-input {
            width: 200px;
        }

        .state .ant-select {
            width: 100px;
        }

        .postcode .ant-input {
            width: 100px;
        }

        .broker .ant-select {
            width: 400px;
        }

        .bdm .ant-select {
            width: 300px;
        }
        .referral-partner-company-name,
        .referral-partner-first-name,
        .referral-partner-last-name,
        .referral-partner-phone,
        .first-name,
        .last-name,
        .phone {
            .ant-input {
                width: 200px;
            }
        }

        .referral-partner-email,
        .email {
            .ant-input {
                width: 400px;
            }
        }

        .other-source .ant-input {
            width: 400px;
        }

        .phone-source .ant-select {
            width: 200px;
        }

        .referral-partner .ant-select {
            width: 400px;
        }
    }

    main.calculator {
        .ant-space.quote {
            float: right;
        }

        .ant-form-item-label {
            width: 220px;
            align-self: center;
        }

        .loan-amount .ant-input-group {
            width: 200px;
        }

        .current-debt .ant-input-group {
            width: 200px;
        }

        .property-value .ant-input-group {
            width: 200px;
        }

        .lvr .ant-input-group {
            width: 150px;
        }

        .postcode .ant-input {
            width: 100px;
        }

        .property-category .ant-input {
            width: 300px;
        }

        .term-months .ant-input {
            width: 100px;
        }

        .interest-rate .ant-input-group {
            width: 120px;
        }

        .default-interest-rate .ant-input-group {
            width: 150px;
        }

        .maximum-lvr .ant-input-group {
            width: 120px;
        }

        .commitment-fee .ant-input-group {
            width: 200px;
        }

        .establishment-fee-dollars .ant-input-group {
            width: 200px;
        }

        .establishment-fee-percentage .ant-input-group {
            width: 120px;
        }

        .application-fee-dollars .ant-input-group {
            width: 200px;
        }

        .application-fee-percentage .ant-input-group {
            width: 120px;
        }

        .brokerage-fee-dollars .ant-input-group {
            width: 200px;
        }

        .brokerage-fee-percentage .ant-input-group {
            width: 120px;
        }

        .legal-fees .ant-input-group {
            width: 200px;
        }

        .approximate-outlays .ant-input-group {
            width: 200px;
        }

        .loan-amount-total .ant-input-group {
            width: 200px;
        }

        .interest-payable .ant-input-group {
            width: 200px;
        }

        .establishment-fee-total .ant-input-group {
            width: 200px;
        }

        .application-fee-total .ant-input-group {
            width: 200px;
        }

        .brokerage-fee-total .ant-input-group {
            width: 200px;
        }

        .legal-fees-total .ant-input-group {
            width: 200px;
        }

        .total-costs-dollars .ant-input-group {
            width: 200px;
        }

        .total-costs-percentage .ant-input-group {
            width: 150px;
        }

        .total-costs-percentage-per-annum .ant-input-group {
            width: 150px;
        }

        .monthly-interest-payments .ant-input-group {
            width: 200px;
        }

        .monthly-interest-payments-default .ant-input-group {
            width: 200px;
        }

        .gross-balance-on-settlement .ant-input-group {
            width: 200px;
        }

        .net-prepaid-balance-on-settlement .ant-input-group {
            width: 200px;
        }

        .net-monthly-balance-on-settlement .ant-input-group {
            width: 200px;
        }
    }

    main.quotes {
        .ant-descriptions table {
            width: 100%;
        }

        .ant-table {
            .ant-tag {
                margin: 0;
                font-size: 10px;
            }
        }
    }
}

.lead-calculator-missing-details-modal {
    .ant-form-item-label {
        width: 150px;
    }
}

.lead-quote-send-modal {
    .ant-form-item-label {
        width: 200px;
    }

    .first-name .ant-input {
        width: 200px;
    }

    .last-name .ant-input {
        width: 200px;
    }

    .email .ant-input {
        width: 250px;
    }
}

.deal-establishment-fee-minimum-modal {
    .ant-form-item-control {
        width: 100px;
    }
}

.lead-quote-reject-modal {
    .ant-form-item-label {
        width: 150px;
    }
}
