.deal-properties {
    .add-property {
        float: right;
    }
}

.deal-property-add-modal,
.deal-property-edit-modal,
.deal-property-upgrade-address-version-modal {
    .upgrade-address-version {
        margin-left: 10px;
    }

    .ant-form-item-label {
        width: 230px;
    }

    .street-address .ant-input {
        width: 250px;
    }

    .unit-number .ant-input {
        width: 100px;
    }

    .street-number .ant-input {
        width: 100px;
    }

    .suburb .ant-input {
        width: 200px;
    }

    .street-address .ant-input {
        width: 200px;
    }

    .street-name .ant-input {
        width: 200px;
    }

    .street-type .ant-select {
        width: 250px;
    }

    .state .ant-select {
        width: 100px;
    }

    .postcode .ant-input {
        width: 100px;
    }

    .estimated-value .ant-input-group {
        width: 200px;
    }

    .current-debt .ant-input-group {
        width: 200px;
    }

    .zone-type .ant-select {
        width: 250px;
    }

    .lot-size-square-metres .ant-input-group {
        width: 200px;
    }

    .dwelling-count .ant-input-number {
        width: 100px;
    }

    .insurance-replacement-value .ant-input-group {
        width: 200px;
    }

    .loan-amount .ant-input-group {
        width: 200px;
    }

    .maximum-lvr .ant-input-group {
        width: 150px;
    }

    .address-search,
    .postcode {
        margin-bottom: 0;
    }

    .address-search .ant-form-item-label {
        width: 130px;
    }
    
    .use-manual,
    .use-search {
        padding:0;
        float:right;
    }
}

.deal-property-edit-modal {
    .upgrade-address-version {
        background: none;
        color: #1890ff;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
}

.deal-property-upgrade-address-version-modal {
    .original-address {
        margin-bottom: 15px;
    }
}

.deal-documents {
    .add-document {
        float: right;

        .ant-upload-list {
            display: none;
        }
    }
}

.deal-notes {
    .ant-form-item-label {
        width: 200px;
    }

    .ant-timeline-item {
        .ant-timeline-item-label {
            width: calc(~'20% - 12px');
        }

        .ant-timeline-item-tail {
            left: 20%;
        }

        .ant-timeline-item-head {
            left: 20%;
        }

        .ant-timeline-item-content {
            left: calc(~'20% - 4px');
            width: calc(~'80% - 14px');
            white-space: pre-wrap;
        }
    }

    .add-note {
        margin-left: 200px;
    }
}

.deal-history {
    .ant-timeline {
        .ant-timeline-item {
            .ant-timeline-item-label {
                width: calc(~'20% - 12px');
            }

            .ant-timeline-item-tail {
                left: 20%;
            }

            .ant-timeline-item-head {
                left: 20%;
            }

            .ant-timeline-item-content {
                left: calc(~'20% - 4px');
                width: calc(~'80% - 14px');
                white-space: pre-wrap;
            }
        }
    }
}

.deal-notes {
    .ant-timeline {
        .ant-timeline-item {
            .ant-timeline-item-label {
                width: calc(~'20% - 12px');
            }

            .ant-timeline-item-tail {
                left: 20%;
            }

            .ant-timeline-item-head {
                left: 20%;
            }

            .ant-timeline-item-content {
                left: calc(~'20% - 4px');
                width: calc(~'80% - 14px');
                white-space: pre-wrap;
            }
        }
    }
}

.deal-follow-up-modal {
    .ant-form-item-label {
        width: 150px;
    }
}

.deal-broker-change {
    .broker .ant-select {
        width: 330px;
    }
    .ant-form-item-label {
        width: 60px;
    }
}
