.referral-partners {
    .actions {
        float: right;
    }
}

.referral-partner-add-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .first-name .ant-input {
        width: 200px;
    }

    .last-name .ant-input {
        width: 200px;
    }

    .phone .ant-input {
        width: 200px;
    }

    .email .ant-input {
        width: 300px;
    }

    .company-name .ant-input {
        width: 300px;
    }

    .abn .ant-input {
        width: 200px;
    }

    .group-name .ant-input {
        width: 300px;
    }

    .street-address .ant-input {
        width: 300px;
    }

    .suburb .ant-input {
        width: 300px;
    }

    .state .ant-select {
        width: 100px;
    }

    .postcode .ant-input {
        width: 100px;
    }

    .type .ant-select {
        width: 200px;
    }

    .acl .ant-form-item-label {
        width: 300px;
    }

    .banned .ant-form-item-label {
        width: 300px;
    }

    .charges-fee .ant-form-item-label {
        width: 300px;
    }

    .conducts-business-in-person .ant-form-item-label {
        width: 300px;
    }

    .discloses-benefits .ant-form-item-label {
        width: 300px;
    }

    .refers-client .ant-form-item-label {
        width: 300px;
    }

    .includes-customer-info .ant-form-item-label {
        width: 300px;
    }

    .nccp .ant-form-item-label {
        width: 300px;
    }

    .aggregator .ant-select {
        width: 300px;
    }

    .linked-in-url .ant-input {
        width: 300px;
    }
}