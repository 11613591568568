.loans {
    .actions {
        float: right;
    }

    .columns {
        width: 100%;

        > .ant-space-item {
            width: calc(~'100% / 4');

            > .ant-card {
                > .ant-card-head {
                    padding: 12px 12px 4px 12px;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: normal;
                    background-color: #f5f5f5;
                    min-height: auto;

                    .ant-card-head-title {
                        padding: 0;

                        .amount {
                            font-size: 12px;
                            color: rgba(0, 0, 0, 0.5);
                        }
                    }
                }

                > .ant-card-body {
                    padding: 8px;
                    background-color: #f5f5f5;

                    > .ant-space {
                        width: 100%;

                        .ant-card {
                            &.maturing {
                                border: 1px solid #ffe58f;
                            }

                            &.maturing-soon {
                                border: 2px solid #ff4d4f;
                            }
                        }

                        .ant-card-body {
                            padding: 16px;

                            .code {
                                font-size: 11px;
                                color: #999;
                            }

                            .name {
                                font-weight: bold;
                                display: block;
                            }

                            .amount {
                                width: 50%;
                                display: inline-block;
                            }

                            .interest-rate {
                                width: 50%;
                                display: inline-block;
                                text-align: right;
                            }

                            .term {
                                font-size: 12px;
                            }

                            .discharge-date,
                            .grace-period-end-date,
                            .end-date,
                            .next-payment-date {
                                font-size: 11px;
                                color: #999;
                            }

                            .overdue-date {
                                font-size: 11px;
                                color: #ff4d4f;
                                font-weight: bold;
                            }

                            .time-in-status {
                                float: right;

                                &.default .ant-badge-count {
                                    background-color: #d9d9d9;
                                }

                                &.warning .ant-badge-count {
                                    background-color: #faad14;
                                }

                                &.danger .ant-badge-count {
                                    background-color: #ff4d4f;
                                }
                            }

                            .assignee-selector {
                                display: block;

                                svg {
                                    fill: #999;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }

            &:nth-child(3) {
                > .ant-card {
                    border: 1px solid #ffe58f;

                    > .ant-card-head,
                    > .ant-card-body {
                        background-color: #fffbe6;
                    }
                }
            }

            &:nth-child(n+4) {
                > .ant-card {
                    border: 1px solid #ffccc7;

                    > .ant-card-head,
                    > .ant-card-body {
                        background-color: #ecc;

                        .ant-card {
                            border: 1px solid #ffccc7;
                        }
                    }
                }
            }
        }
    }

    &.forecast {
        .forecast-current {
            margin-bottom: 30px;
        }

        table {
            tr {
                td {
                    &.date,
                    &.day,
                    &.daily-total,
                    &.loan-code,
                    &.loan-name,
                    &.loan-amount {
                        vertical-align: top;
                    }
                }
            }
        }
    }
}

.loans-dashboard-change-filters-modal {
    .ant-form-item-label {
        width: 120px;
    }
}

.loans-search-dropdown {
    .result {
        .code,
        .salesforce-code {
            display: inline;
            font-size: 11px;
            color: #999;
        }

        .salesforce-code {
            &:before {
                content: ' (';
            }

            &::after {
                content: ')'
            }
        }

        .name {
            display: block;
        }
    }
}