.investments {
    .actions {
        float: right;
    }

    .columns {
        width: 100%;

        > .ant-space-item {
            width: calc(~'100% / 4');

            > .ant-card {
                > .ant-card-head {
                    padding: 12px 12px 4px 12px;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: normal;
                    background-color: #f5f5f5;
                    min-height: auto;

                    .ant-card-head-title {
                        padding: 0;

                        .amount {
                            font-size: 12px;
                            color: rgba(0, 0, 0, 0.5);
                        }
                    }
                }

                > .ant-card-body {
                    padding: 8px;
                    background-color: #f5f5f5;

                    > .ant-space {
                        width: 100%;

                        .ant-card-body {
                            padding: 16px;

                            .code {
                                font-size: 11px;
                                color: #999;
                            }

                            .name {
                                font-weight: bold;
                                display: block;
                            }

                            .amount {
                                width: 50%;
                                display: inline-block;
                            }

                            .interest-rate {
                                width: 50%;
                                display: inline-block;
                                text-align: right;
                            }

                            .term {
                                font-size: 12px;
                            }

                            .loan-code {
                                font-size: 11px;
                                color: #999;
                            }

                            .created-time {
                                font-size: 11px;
                                color: #999;
                            }

                            .grace-period-status {
                                background-color: #fffbe6;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.investments-search-dropdown {
    .result {
        .code,
        .salesforce-code {
            display: inline;
            font-size: 11px;
            color: #999;
        }

        .salesforce-code {
            &:before {
                content: ' (';
            }

            &::after {
                content: ')'
            }
        }

        .name {
            display: block;
        }
    }
}
