.administrator {
    main {
        &.edit {
            .ant-form-item-label {
                width: 200px;
            }

            .name .ant-input {
                width: 300px;
            }

            .email .ant-input {
                width: 400px;
            }

            .photo-url .ant-input {
                width: 700px;
            }

            .title .ant-input {
                width: 300px;
            }

            .role .ant-select {
                width: 300px;
            }

            .deal-minimum-value .ant-input {
                width: 200px;
            }

            .deal-maximum-value .ant-input {
                width: 200px;
            }

            .permissions .ant-select {
                width: 700px;
            }
        }

        &.history {
            .ant-timeline-item {
                .ant-timeline-item-label {
                    width: calc(~'20% - 12px');
                }

                .ant-timeline-item-tail {
                    left: 20%;
                }

                .ant-timeline-item-head {
                    left: 20%;
                }

                .ant-timeline-item-content {
                    left: calc(~'20% - 4px');
                    width: calc(~'80% - 14px');
                    white-space: pre-wrap;
                }
            }
        }
    }
}
