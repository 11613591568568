.leads-movement-report {
    .actions {
        .date-picker {
            .ant-form-item {
                margin: 0;
            }
        }
    }

    .chart-left,
    .chart-right {
        margin: 5px;
        padding: 8px;

        &.chart-left {
            margin-left: 0px;
        }

        &.chart-right {
            margin-right: 0px;
        }
    }
}
