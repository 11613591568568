@import '~antd/lib/style/themes/default.less';

.application {
    >.header {
        .workflow-dropdown-button {
            margin-left: -32px;
            margin-top: 2px;
        }

        .workflow-status {
            margin-right: 0px;
        }

        .settlement-date {
            .ant-btn-link {
                padding: 0;
                height: auto;
            }
        }

        .loan-processors-selector {
            width: 100%;
        }
    }

    main.overview {
        h3 {
            margin-top: 30px;
        }

        .recalculate {
            float: right;
            margin-top: 30px;
        }
    }

    main.edit {
        .ant-form-item-label {
            width: 250px;
        }

        .code-type .ant-select {
            width: 200px;
        }

        .discharge-interest-type .ant-select {
            width: 200px;
        }

        .mortgage-type .ant-select {
            width: 300px;
        }

        .loan-purpose .ant-select {
            width: 400px;
        }

        .repayment-strategy .ant-select {
            width: 400px;
        }

        .secondary-repayment-strategy .ant-select {
            width: 400px;
        }

        .interest-payment-method .ant-select {
            width: 200px;
        }

        .primary-borrower .ant-select {
            width: 400px;
        }

        .term-months-minimum .ant-select {
            width: 200px;
        }

        .disbursal-type .ant-select {
            width: 200px;
        }

        .broker-commission .ant-input-group {
            width: 200px;
        }

        .bank-name .ant-input {
            width: 300px;
        }

        .bank-bsb .ant-input {
            width: 150px;
        }

        .bank-account-name .ant-input {
            width: 400px;
        }

        .bank-account-number .ant-input {
            width: 250px;
        }

        .bank-branch-address .ant-input {
            width: 400px;
        }

        .bank-branch-state .ant-select {
            width: 150px;
        }

        .bank-branch-postcode .ant-input {
            width: 100px;
        }

        .solicitor-firm-name .ant-input {
            width: 400px;
        }

        .solicitor-name .ant-input {
            width: 400px;
        }

        .solicitor-mobile .ant-input {
            width: 200px;
        }

        .solicitor-phone .ant-input {
            width: 200px;
        }

        .solicitor-email .ant-input {
            width: 400px;
        }

        .accountant-firm-name .ant-input {
            width: 400px;
        }

        .accountant-name .ant-input {
            width: 400px;
        }

        .accountant-phone .ant-input {
            width: 200px;
        }

        .accountant-email .ant-input {
            width: 400px;
        }

        .application-solicitor {
            .ant-select {
                width: 200px;
            }
        }

        .credit-notes-conditions {
            .ant-input {
                width: 500px;
            }
        }

        .loan-synopsis {
            .ant-input {
                width: 500px;
            }
        }
    }

    main.disbursements {
        .ant-alert {
            margin-bottom: 15px;
        }

        .actions {
            float: right;
        }
    }

    main.warehouses {
        .ant-alert {
            margin-bottom: 15px;
        }

        .actions {
            float: right;
        }
    }

    main.fees {
        .actions {
            float: right;
        }
    }

    main.borrowers {
        .add-borrower {
            float: right;
        }
    }

    main.borrower {
        .actions {
            float: right;
        }

        .ant-form-item-label {
            width: 200px;
        }

        .category .ant-select {
            width: 200px;
        }

        .type .ant-select {
            width: 200px;
        }

        .first-name .ant-input {
            width: 200px;
        }

        .middle-name .ant-input {
            width: 200px;
        }

        .last-name .ant-input {
            width: 200px;
        }

        .phone .ant-input {
            width: 150px;
        }

        .email .ant-input {
            width: 400px;
        }

        .dob .ant-input {
            width: 200px;
        }

        .gender .ant-select {
            width: 200px;
        }

        .id-type .ant-select {
            width: 200px;
        }

        .id-number .ant-input {
            width: 200px;
        }

        .licence-number .ant-input {
            width: 200px;
        }

        .licence-state .ant-select {
            width: 200px;
        }

        .passport-country .ant-select {
            width: 300px;
        }

        .passport-number .ant-input {
            width: 200px;
        }

        .address .ant-input {
            width: 600px;
        }

        .income-type .ant-select {
            width: 250px;
        }

        .job-title .ant-input {
            width: 300px;
        }

        .dependent-number .ant-select {
            width: 100px;
        }

        .marital-status .ant-select {
            width: 200px;
        }

        .residency-status .ant-select {
            width: 200px;
        }

        .yearly-income .ant-input-group {
            width: 200px;
        }

        .monthly-household-expenses .ant-input-group {
            width: 150px;
        }

        .total-assets .ant-input-group {
            width: 200px;
        }

        .total-liabilities .ant-input-group {
            width: 200px;
        }

        .business-name .ant-input {
            width: 300px;
        }

        .abn .ant-input {
            width: 150px;
        }

        .credit-score .ant-input {
            width: 150px;
        }

        .monthly-gross-turnover .ant-input-group {
            width: 200px;
        }

        .monthly-gross-expenses .ant-input-group {
            width: 200px;
        }

        .trust-name .ant-input {
            width: 300px;
        }

        .trustee-type .ant-select {
            width: 200px;
        }

        .trustee-name .ant-input {
            width: 300px;
        }
    }

    main.calculator {
        .save {
            float: right;
        }

        .ant-form-item-label {
            width: 220px;
        }

        .loan-amount .ant-input-group {
            width: 200px;
        }

        .current-debt .ant-input-group {
            width: 200px;
        }

        .property-value .ant-input-group {
            width: 200px;
        }

        .lvr .ant-input-group {
            width: 150px;
        }

        .postcode .ant-input {
            width: 100px;
        }

        .property-category .ant-input {
            width: 300px;
        }

        .term-months .ant-input-group {
            width: 200px;
        }

        .interest-rate .ant-input-group {
            width: 120px;
        }

        .default-interest-rate .ant-input-group {
            width: 150px;
        }

        .maximum-lvr .ant-input-group {
            width: 120px;
        }

        .commitment-fee .ant-input-group {
            width: 200px;
        }

        .establishment-fee-dollars .ant-input-group {
            width: 200px;
        }

        .establishment-fee-percentage .ant-input-group {
            width: 120px;
        }

        .application-fee-dollars .ant-input-group {
            width: 200px;
        }

        .application-fee-percentage .ant-input-group {
            width: 120px;
        }

        .brokerage-fee-dollars .ant-input-group {
            width: 200px;
        }

        .brokerage-fee-percentage .ant-input-group {
            width: 120px;
        }

        .legal-fees .ant-input-group {
            width: 200px;
        }

        .approximate-outlays .ant-input-group {
            width: 200px;
        }

        .loan-amount-total .ant-input-group {
            width: 200px;
        }

        .interest-payable .ant-input-group {
            width: 200px;
        }

        .establishment-fee-total .ant-input-group {
            width: 200px;
        }

        .application-fee-total .ant-input-group {
            width: 200px;
        }

        .brokerage-fee-total .ant-input-group {
            width: 200px;
        }

        .legal-fees-total .ant-input-group {
            width: 200px;
        }

        .total-costs-dollars .ant-input-group {
            width: 200px;
        }

        .total-costs-percentage .ant-input-group {
            width: 150px;
        }

        .total-costs-percentage-per-annum .ant-input-group {
            width: 150px;
        }

        .monthly-interest-payments .ant-input-group {
            width: 200px;
        }

        .monthly-interest-payments-default .ant-input-group {
            width: 200px;
        }

        .gross-balance-on-settlement .ant-input-group {
            width: 200px;
        }

        .net-prepaid-balance-on-settlement .ant-input-group {
            width: 200px;
        }

        .net-monthly-balance-on-settlement .ant-input-group {
            width: 200px;
        }

        .gross-loan-amount,
        .net-prepaid-balance-on-settlement {
            .difference {
                &.negative {
                    color: #ff4d4f;
                }

                &.positive {
                    color: #52c41a;
                }
            }
        }

        .net-prepaid-balance-on-settlement {
            .ant-form-item-control {
                width: 220px;
            }
        }
    }

    main.conditional-approvals {
        .ant-space.issue {
            float: right;
        }
    }

    main.conditional-approval {
        .ant-space.issue {
            float: right;
        }

        .ant-form-item-label {
            width: 200px;
        }

        .loan-type .ant-select {
            width: 200px;
        }

        .lender .ant-select {
            width: 500px;
        }

        .standard-conditions,
        .custom-conditions {
            .ant-checkbox-wrapper {
                display: block;
                line-height: 32px;
                margin-left: 0;
            }
        }

        .custom-condition-add .ant-input {
            width: 500px;
        }
    }

    main.conditions {
        .ant-form-item-label {
            width: 200px;
        }

        .ant-form-item.condition {
            margin-top: 24px;
        }

        .add-condition {
            margin-left: 200px;
        }
    }

    main.property-valuations {
        .property-valuation {
            .ant-descriptions {
                margin-bottom: 15px;
                padding: 0px;
            }

            .valuers-title {
                margin-top: 30px;
            }

            .workflow-dropdown-button {
                button:first-child:not(:last-child) {
                    width: 160px;
                }
            }

            .uploaded-condition-document-list {
                .document {
                    &.approved {
                        color: @success-color;
                    }

                    &.pending {
                        color: @warning-color;
                    }

                    &.rejected {
                        color: @error-color;
                    }
                }
            }
        }
    }

    main.solicitor-instructions {
        .actions {
            float: right;
        }

        .ant-form-item-label,
        .ant-select {
            width: 200px;
        }
    }

    main.solicitor-downloads {
        .download-button {
            float: right;
            margin-top: 24px;
        }

        .document {
            &.approved {
                color: @success-color;
            }
        }
    }

    main.underwriting {
        .uploaded-condition-document-list {
            .document {
                &.approved {
                    color: @success-color;
                }

                &.pending {
                    color: @warning-color;
                }

                &.rejected {
                    color: @error-color;
                }
            }
        }

        .rfi-item {
            &.requested {
                color: @warning-color;
            }

            &.supplied {
                color: @success-color;
            }
        }
    }

    main.required-information {
        .uploaded-condition-document-list {
            .document {
                &.approved {
                    color: @success-color;
                }

                &.pending {
                    color: @warning-color;
                }

                &.rejected {
                    color: @error-color;
                }
            }
        }
    }

    main.formal-approvals {
        .ant-space.issue {
            float: right;
        }
    }

    main.history {
        .ant-timeline {
            .ant-timeline-item {
                .ant-timeline-item-label {
                    width: calc(~'20% - 12px');
                }

                .ant-timeline-item-tail {
                    left: 20%;
                }

                .ant-timeline-item-head {
                    left: 20%;
                }

                .ant-timeline-item-content {
                    left: calc(~'20% - 4px');
                    width: calc(~'80% - 14px');
                    white-space: pre-wrap;
                }
            }
        }
    }

    main.notes {
        .ant-form-item-label {
            width: 200px;
        }

        .ant-timeline-item {
            .ant-timeline-item-label {
                width: calc(~'20% - 12px');
            }

            .ant-timeline-item-tail {
                left: 20%;
            }

            .ant-timeline-item-head {
                left: 20%;
            }

            .ant-timeline-item-content {
                left: calc(~'20% - 4px');
                width: calc(~'80% - 14px');
                white-space: pre-wrap;
            }
        }

        .add-note {
            margin-left: 200px;
        }
    }
}

.uploaded-condition-document-dropdown {
    max-width: 220px;

    .ant-dropdown-menu-item-group-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.application-borrower-modal {
    .ant-form-item-label {
        width: 200px;
    }

    .category .ant-select {
        width: 200px;
    }

    .type .ant-select {
        width: 200px;
    }

    .first-name .ant-input {
        width: 200px;
    }

    .last-name .ant-input {
        width: 200px;
    }

    .phone .ant-input {
        width: 150px;
    }

    .email .ant-input {
        width: 250px;
    }

    .dob .ant-input {
        width: 200px;
    }

    .gender .ant-select {
        width: 200px;
    }

    .id-type .ant-select {
        width: 200px;
    }

    .id-number .ant-input {
        width: 200px;
    }

    .address .ant-input {
        width: 250px;
    }

    .income-type .ant-select {
        width: 250px;
    }

    .job-title .ant-input {
        width: 300px;
    }

    .dependent-number .ant-select {
        width: 100px;
    }

    .marital-status .ant-select {
        width: 200px;
    }

    .yearly-income .ant-input-group {
        width: 200px;
    }

    .monthly-household-expenses .ant-input-group {
        width: 150px;
    }

    .business-name .ant-input {
        width: 250px;
    }

    .abn .ant-input {
        width: 150px;
    }

    .monthly-gross-turnover .ant-input-group {
        width: 200px;
    }

    .monthly-gross-expenses .ant-input-group {
        width: 200px;
    }

    .credit-score .ant-input {
        width: 150px;
    }

    .residency-status .ant-select {
        width: 200px;
    }

    .trust-name .ant-input {
        width: 250px;
    }

    .trustee-name .ant-input {
        width: 250px;
    }
}

.application-borrower-bankruptcy-check-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .first-name .ant-input {
        width: 200px;
    }

    .middle-name {
        .ant-input {
            width: 200px;
        }

        .ant-input-group-wrapper {
            width: auto;
        }
    }

    .last-name .ant-input {
        width: 200px;
    }

    .dob .ant-input {
        width: 200px;
    }
}

.application-borrower-company-trading-history-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .business-name .ant-input {
        width: 200px;
    }

    .abn .ant-input {
        width: 200px;
    }
}

.application-borrower-credit-check-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .first-name .ant-input {
        width: 200px;
    }

    .last-name .ant-input {
        width: 200px;
    }

    .dob .ant-input {
        width: 200px;
    }

    .gender .ant-select {
        width: 200px;
    }

    .id-type .ant-select {
        width: 200px;
    }

    .id-number .ant-input {
        width: 200px;
    }

    .address .ant-input {
        width: 300px;
    }
}

.application-close-modal {
    .ant-form-item-label {
        width: 150px;
    }
}

.application-conditional-send-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .first-name .ant-input {
        width: 200px;
    }

    .last-name .ant-input {
        width: 200px;
    }

    .email .ant-input {
        width: 250px;
    }
}

.application-disbursement-add-modal,
.application-disbursement-edit-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .amount .ant-input-number-input {
        width: 200px;
    }

    .description .ant-input {
        width: 300px;
    }

    .payee-name .ant-input {
        width: 300px;
    }

    .type {
        .ant-select {
            width: 250px;
        }

        .ant-form-item-explain-error {
            width: 250px;
        }
    }
}

.application-fee-add-modal,
.application-fee-edit-modal {
    .ant-form-item-label {
        width: 200px;
    }

    .amount .ant-input-group {
        width: 200px;
    }

    .description .ant-input {
        width: 200px;
    }
}

.application-settlement-modal {
    .ant-form-item-label {
        width: 200px;
    }
}

.application-settled-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .ant-alert {
        margin-bottom: 20px;
    }
}

.property-valuation-enter-valuation-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .ant-input {
        width: 200px;
    }
}

.property-valuation-order-valuation-modal {
    .ant-form-item-label {
        width: 175px;
    }

    .ant-form-item-control,
    .ant-input {
        width: 200px;
    }
}

.property-insurance-expiry-date-modal,
.property-valuation-enter-quote-modal,
.property-valuation-inspection-date-modal,
.property-valuation-received-date-modal,
.property-valuation-request-quote-modal,
.property-valuation-update-due-date-modal,
.property-valuation-update-quote-modal,
.property-valuation-update-time-frame-modal,
.property-valuation-update-valuation-value-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .ant-form-item-control {
        width: 200px;
    }

    .ant-input-group-wrapper {
        width: 200px;
    }
}

.solicitor-send-instructions-modal {
    .ant-alert {
        margin-bottom: 15px;
    }

    .ant-form-item-label {
        width: 175px;
    }

    .ant-form-item-control,
    .ant-input {
        width: 200px;
    }
}

.application-conditional-missing-details-modal {
    .ant-form-item-label {
        width: 150px;
    }
}

.application-generate-credit-memo-modal {
    .credit-notes-conditions {
        .ant-form-item-label {
            width: 200px;
        }
    }

    .loan-synopsis {
        .ant-form-item-label {
            width: 200px;
        }
    }
}