.brokers {
    .actions {
        float: right;
    }

    .columns {
        width: 100%;

        > .ant-space-item {
            width: calc(~'100% / 7');

            > .ant-card {
                > .ant-card-head {
                    padding: 12px 12px 4px 12px;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: normal;
                    background-color: #f5f5f5;
                    min-height: auto;

                    .ant-card-head-title {
                        padding: 0;
                    }
                }

                > .ant-card-body {
                    padding: 8px;
                    background-color: #f5f5f5;

                    > .ant-space {
                        width: 100%;

                        .ant-card-body {
                            padding: 16px;

                            .pending {
                                float: right;
                            }

                            .code {
                                font-size: 11px;
                                color: #999;
                            }

                            .name {
                                font-weight: bold;
                            }

                            .aggregator {
                                font-size: 12px;
                            }

                            .created-time {
                                font-size: 11px;
                                color: #999;
                            }

                            .reschedule {
                                font-size: 11px;
                            }

                            .bdm-selector {
                                display: block;

                                svg {
                                    fill: #999;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }

                .no-contact-date-next {
                    border: 1px solid #faad14;
                }

                .contact-today {
                    border: 2px solid #00cc00;
                }

                .contact-overdue {
                    border: 3px solid #ff4d4f;
                }
            }
        }
    }
}

.broker-add-modal {
    .ant-form-item-label {
        width: 200px;
    }
}

.broker-reject-modal {
    .ant-form-item-label {
        width: 200px;
    }
}

.broker-next-contact-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .date .ant-input {
        width: 200px;
    }
}

.broker-search-option {
    .option-name {
        margin-left: 5px;
    }

    .option-tag {
        margin: 0px 5px;
    }
}
