.applications-settlement-report {
    .actions {
        .date-picker {
            .ant-form-item {
                margin: 0;
            }
        }
    }
    .settled-applications-count,
    .settled-applications-value {
        margin: 5px;
        margin-top: 20px;
        padding: 8px;
    
        &.settled-applications-count {
            margin-left: 0px;
        }
    
        &.settled-applications-value {
            margin-right: 0px;
        }
    
        .anticon,
        .ant-statistic-content-value {
            color: #75d7ad;
            font-size: 1.5em;
            margin-bottom: 16px;
        }
    }
    .pie-chart-left,
    .pie-chart-right {
        margin: 5px;
        padding: 8px;
        &.pie-chart-left {
            margin-left: 0px;
        }
        &.pie-chart-right {
            margin-right: 0px;
        }
    }
}