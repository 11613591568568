.withdrawal-aba {
    main {
        &.history {
            .ant-timeline-item {
                .ant-timeline-item-label {
                    width: calc(~'20% - 12px');
                }

                .ant-timeline-item-tail {
                    left: 20%;
                }

                .ant-timeline-item-head {
                    left: 20%;
                }

                .ant-timeline-item-content {
                    left: calc(~'20% - 4px');
                    width: calc(~'80% - 14px');
                    white-space: pre-wrap;
                }
            }
        }

        &.transactions {
            .ant-table-selection-column {
                width: 3%;
            }

            .actions {
                float: right;
            }

            .hidden {
                display: none;
            }
        }
    }
}
