.ant-layout {
    &.loan {
        >.header {
            .discharge-date {
                .ant-btn-link {
                    padding: 0;
                    height: auto;
                }
            }

            .grace-period-status {
                border: 1px solid #ffe58f;
                background-color: #fffbe6;
            }

            .additional-action-button {
                margin-left: -32px;
                margin-top: 2px;
            }
        }

        .content {
            &.edit {
                .ant-form-item-label {
                    width: 200px;
                }

                .interest-amount-prepaid .ant-input-group {
                    width: 200px;
                }

                .term-months-minimum .ant-select {
                    width: 200px;
                }

                .discharge-method .ant-select {
                    width: 200px;
                }

                .application .ant-select {
                    width: 500px;
                }
            }

            &.notes {
                .ant-form-item-label {
                    width: 200px;
                }

                .ant-timeline-item {
                    .ant-timeline-item-label {
                        width: calc(~'20% - 12px');
                    }

                    .ant-timeline-item-tail {
                        left: 20%;
                    }

                    .ant-timeline-item-head {
                        left: 20%;
                    }

                    .ant-timeline-item-content {
                        left: calc(~'20% - 4px');
                        width: calc(~'80% - 14px');
                        white-space: pre-wrap;
                    }
                }

                .add-note {
                    margin-left: 200px;
                }
            }

            &.history {
                .ant-timeline-item {
                    .ant-timeline-item-label {
                        width: calc(~'20% - 12px');
                    }

                    .ant-timeline-item-tail {
                        left: 20%;
                    }

                    .ant-timeline-item-head {
                        left: 20%;
                    }

                    .ant-timeline-item-content {
                        left: calc(~'20% - 4px');
                        width: calc(~'80% - 14px');
                        white-space: pre-wrap;
                    }
                }
            }

            &.documents {
                .add-document {
                    float: right;

                    .ant-upload-list {
                        display: none;
                    }
                }
            }

            &.payout-figure {
                .ant-form-item-label {
                    width: 200px;
                }

                .amount .ant-input-number-input {
                    width: 200px;
                }

                .borrower-name .ant-input {
                    width: 400px;
                }

                .discharge-date .ant-input {
                    width: 200px;
                }

                .line-item {
                    align-items: flex-start;

                    .label {
                        display: block;
                        width: 500px;
                    }

                    .dropdown-label {
                        .ant-form-item-label {
                            width: 500px;
                            display: flex;
                            align-items: flex-start;

                            >label {
                                height: inherit;
                            }
                        }
                    }

                    .ant-form-item {
                        margin: 0;
                    }
                }
            }
        }
    }

    &.loans {
        .grace-period-status {
            background-color: #fffbe6;
            font-size: 12px;
        }
    }
}

.loan-fee-add-modal,
.loan-fee-edit-modal {
    .ant-form-item-label {
        width: 200px;
    }

    .amount .ant-input-group {
        width: 200px;
    }

    .description .ant-input {
        width: 200px;
    }
}

.loan-fund-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .investor-rate .ant-input-group {
        width: 150px;
    }
}

.loan-create-application-modal {
    .loan-extend {
        margin-top: 15px;

        .ant-form-item-label {
            width: 130px;
        }

        .ant-form-item-control {
            width: 300px;
        }
    }
}

.loan-sell-loan-modal {
    h3 {
        text-align: center;
        margin-bottom: 24px;
    }

    .ant-form-item-label {
        width: 210px;
    }

    .amount-principal {
        .ant-input-group {
            width: 228px;
        }
    }

    .transaction-date {
        .transaction-pick-date {
            width: 130px;
        }

        .transaction-pick-time {
            width: 90px;
        }
    }

    .ant-form-item-explain-error {
        inline-size: 228px;
        overflow-wrap: break-word;
    }
}

.loan-create-extension-application-modal {
    .ant-input-group {
        width: 200px;
    }

    .ant-form-item-label {
        width: 220px;
    }

    .application-fee-percentage,
    .brokerage-fee-percentage,
    .establishment-fee-percentage,
    .interest-rate,
    .maximum-lvr {
        .ant-input-group {
            width: 120px;
        }
    }

    .form-error-message {
        margin: 0 0 16px;

        .ant-alert-message {
            font-size: 14px;
        }

        .ant-alert-description {
            ul {
                margin: 0;
                padding: 0 0 0 24px;
            }
        }
    }
}

.loan-principal-reduction-modal {
    .ant-form-item-label {
        width: 150px;
    }

    .transaction-pick-date {
        width: 130px;
    }

    .transaction-pick-time {
        width: 90px;
    }

    .amount .ant-input-number-group {
        width: 200px;
    }
}

.loan-record-drawdown-modal {
    .ant-form-item-label {
        width: 200px;
    }

    .transaction-pick-date {
        width: 130px;
    }

    .transaction-pick-time {
        width: 90px;
    }

    .ant-input-number-group {
        width: 200px;
    }
}

.loan-payout-figure-interest-calculator-modal {
    .row-header {
        font-weight: bold;
    }
}