.user {
    main {
        &.history {
            .ant-timeline-item {
                .ant-timeline-item-label {
                    width: calc(~'20% - 12px');
                }

                .ant-timeline-item-tail {
                    left: 20%;
                }

                .ant-timeline-item-head {
                    left: 20%;
                }

                .ant-timeline-item-content {
                    left: calc(~'20% - 4px');
                    width: calc(~'80% - 14px');
                    white-space: pre-wrap;
                }
            }
        }

        &.edit {
            .ant-form-item-label {
                width: 200px;
            }

            .first-name .ant-input {   
                width: 400px;
            }

            .last-name .ant-input {   
                width: 400px;
            }
        }
    }
}
