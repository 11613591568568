.body > .accounts {
    main {
        &.investor-movements {
            table {
                tr {
                    td {
                        &.date,
                        &.day,
                        &.daily-deposits,
                        &.daily-withdrawals {
                            vertical-align: top;
                        }
                    }
                }
            }
        }

        &.loan-payments {
            table {
                tr {
                    td {
                        &.date,
                        &.day,
                        &.daily-interest,
                        &.daily-principal {
                            vertical-align: top;
                        }
                    }
                }
            }
        }

        &.payments-from-investors {
            table {
                tr {
                    td {
                        &.date,
                        &.day,
                        &.daily-total {
                            vertical-align: top;
                        }
                    }
                }
            }
        }

        &.payments-to-investors {
            table {
                tr {
                    td {
                        &.date,
                        &.day,
                        &.daily-total {
                            vertical-align: top;
                        }
                    }
                }
            }
        }

        &.warehouse-movements {
            table {
                tr {
                    td {
                        &.date,
                        &.day {
                            vertical-align: top;
                        }
                    }
                }
            }
        }
    }
}